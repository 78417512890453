// Copyright 2020 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

function displaySorting(app, data) {
  const sortBy = localStorage.getItem('sortBy');
  app.$$("#rankSort").addClass('chip-outline');
  app.$$("#suitSort").addClass('chip-outline');
  app.$$("#noSort").addClass('chip-outline');
  if (sortBy === 'rank') {
    app.$$("#rankSort").removeClass('chip-outline');
  } else if (sortBy === 'suit') {
    app.$$("#suitSort").removeClass('chip-outline');
  } else {
    app.$$("#noSort").removeClass('chip-outline');
  }
  if (data.status === 'NOT STARTED') {
    app.$$("#sortCards").hide();
  } else {
    app.$$("#sortCards").show();
  }
}

export {displaySorting};