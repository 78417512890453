// Copyright 2020 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {display} from './display';
import {gameReset} from './misc';
const maxErrorCount = 10;
const requestTimeout = 60000;

// in case if long polling fails, we'll also poll the status every five seconds
const pollingDelay = 5000;
let pollingErrorCount = 0;
let pollingInterval = null;

export function startPolling() {
  if (pollingInterval !== null) {
    // already polling
    return;
  }
  pollingInterval = setInterval(async () => {
    const playerKey = localStorage.getItem('playerKey');
    const gameKey = localStorage.getItem('gameKey');
    try {
      const {data, status} = await app.$f7.request.promise({
        url: `durak/v2/game/${gameKey}/player/${playerKey}/getTable`,
        method: 'GET',
        timeout: requestTimeout,
        dataType: 'json',
        cache: false,
      });
      if (status !== 200) {
        throw new Error('Polling failed');
      }
      const serial = Number(localStorage.getItem('serial'));
      const currentGameKey = localStorage.getItem('gameKey');
      if (data.gameKey === currentGameKey && data.serial > serial) {
        localStorage.setItem('gameData', JSON.stringify(data));
        localStorage.setItem('serial', data.serial);
        display(app, data);
      }
      if (data.status === 'FINISHED') {
        stopPolling();
      }
    } catch (err) {
      ++pollingErrorCount;
      if (pollingErrorCount >= maxErrorCount) {
        stopPolling();
      }
    }
  }, pollingInterval);
}

export function stopPolling() {
  clearInterval(pollingInterval);
  pollingInterval = null;
}

function resubscribe(app, gameKey, playerKey, timeout=1000) {
  setTimeout(() => { subscribe(app, gameKey, playerKey); }, timeout);
}

async function subscribe(app, gameKey, playerKey) {
  let errorCount = Number(localStorage.getItem('subscribeErrorCount')); // 0 if null
  const currentSerial = Number(localStorage.getItem('serial') || 0);

  try {
    const {data, status} = await app.$f7.request.promise(
      {
        url: `durak/v2/subscribe/game/${gameKey}/player/${playerKey}/serial/${currentSerial}`,
        method: 'GET',
        timeout: requestTimeout,
        dataType: 'json',
        cache: false,
      }
    );

    if (status !== 200) {
      throw new Error('Subscription failed');
    }

    const serial = Number(localStorage.getItem('serial'));
    const currentGameKey = localStorage.getItem('gameKey');
    if (data.gameKey === currentGameKey && data.serial > serial) {
      localStorage.setItem('gameData', JSON.stringify(data));
      localStorage.setItem('serial', data.serial);
      display(app, data);
    }
    localStorage.setItem('subscribeErrorCount', '0');
    if (data.status !== 'FINISHED') {
      resubscribe(app, gameKey, playerKey, 0);
    } else {
      stopPolling();
    }
  } catch (err) {
    ++errorCount;
    localStorage.setItem('subscribeErrorCount', String(errorCount));
    if (errorCount < maxErrorCount) {
      resubscribe(app, gameKey, playerKey);
    }
    return;
  }
}

function handleRefresh(app) {
  const $ptrContent = app.$$('.ptr-content');
  $ptrContent.on('ptr:refresh', async () => {
    const playerKey = localStorage.getItem('playerKey');
    const gameKey = localStorage.getItem('gameKey');
    try {
      if (playerKey && gameKey) {
        const {data, status} = await app.$f7.request.promise({
          url: `durak/v2/game/${gameKey}/player/${playerKey}/getTable`,
          method: 'GET',
          timeout: requestTimeout,
          dataType: 'json',
          cache: false,
        });
        if (status !== 200) {
          throw new Error('Refresh failed');
        }
  
        const currentGameKey = localStorage.getItem('gameKey');
        if (data.gameKey === currentGameKey) {
          localStorage.setItem('gameData', JSON.stringify(data));
          gameReset(data);
          display(app, data);
        }

        // if the existing subscription failed, subscribe again on refresh
        let errorCount = Number(localStorage.getItem('subscribeErrorCount')); // 0 if null
        if (errorCount >= maxErrorCount) {
          localStorage.setItem('subscribeErrorCount', '0');
          subscribe(app, data.gameKey, data.playerKey);
        }
      }
    } catch (err) {
      // ignore
    }
    app.$f7.ptr.done();
  });
}

export {subscribe, handleRefresh};
