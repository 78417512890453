// Copyright 2020 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {imgBase64} from "./imgbase64";

const suitDict = {
  '♠': 'spade',
  '♥': 'heart',
  '♦': 'diamond',
  '♣': 'club',
};

const rankDict = {
  '6': 'six',
  '7': 'seven',
  '8': 'eight',
  '9': 'nine',
  '10': 'ten',
  'J': 'jack',
  'Q': 'queen',
  'K': 'king',
  'A': 'ace',
};

function generateImgSrc(rank, suit) {
  // const location = 'static/cards';
  // if (rank === null || suit === null) {
  //   return `${location}/back.png`;
  // }
  // const rankName = rankDict[rank];
  // const suitName = suitDict[suit];
  // return `${location}/card-${rankName}-${suitName}.png`;
  const name = rank === null && suit === null ? 'back' : `card-${rankDict[rank]}-${suitDict[suit]}`;
  return imgBase64[name];
}

class Card {
  constructor(card) {
    if (!card) {
      this.rank = null;
      this.suit = null;
    } else {
      this.rank = card.rank;
      this.suit = card.suit;
    }
  }

  span(properties) {
    properties = Object.assign({}, properties);
    if (!properties['class']) {
      properties['class'] = '';
    }
    properties['class'] = `card ` + properties['class'];
    properties['class'] = properties['class'].trim();
    properties['data-rank'] = this.rank || '';
    properties['data-suit'] = this.suit || '';
    let htmlProperties = '';
    for (const property in properties) {
      htmlProperties += ` ${property}="${properties[property]}"`;
    }
    htmlProperties = htmlProperties.trim();

    const imgSrc = generateImgSrc(this.rank, this.suit);
    const alt = this.rank === null || this.suit === null ? '🂠' : `${this.rank}${this.suit}`;
    const html = `<img src="${imgSrc}" alt="${alt}">`;

    return `<span ${htmlProperties}>${html}</span>`;
  }
}

export {Card};
